import {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  ReactSVGElement
} from 'react';
import Tooltip from '../Tooltip';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  icon?: ReactSVGElement | ReactNode | ReactElement;
  icon2?: ReactSVGElement | ReactNode | ReactElement;
  isActive?: boolean;
  onClick?: () => void;
  tooltip?: boolean;
  divider?: boolean;
}

const Button = ({
  children,
  icon,
  isActive,
  onClick,
  icon2,
  style,
  divider = false,
  tooltip = true
}: ButtonProps) => {
  const blobClass = isActive
    ? 'buttonSidebar__blob--active'
    : 'buttonSidebar__blob';
  const childrenClass = isActive
    ? 'buttonSidebar__children--active'
    : 'buttonSidebar__children';

  return (
    <button
      className={`buttonSidebar${divider ? '--divider' : ''}`}
      onClick={onClick}
    >
      <div className={divider ? '' : blobClass} />
      <div className={childrenClass}>
        {icon}
        <p style={style}>{children}</p>
        {icon2 && <div className="buttonSidebar__icon2">{icon2}</div>}
      </div>
      {tooltip && (
        <Tooltip
          classes="buttonSidebar__tooltip"
          label={children}
          triangle={false}
        />
      )}
    </button>
  );
};

export default Button;
