import { createContext, useEffect, useState } from 'react';
import { NextComponentType } from 'next';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation, SSRConfig } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import { WagmiConfig } from 'wagmi';
import { Cookie, GoSquared } from '@/components';
import { CreateWagmiClient } from '@/services/auth.service';
import createEmotionCache from '@/themes/createEmotionCache';
import DarkSMATMuiTheme from '@/themes/muiDarkTheme';
import theme from '@/themes/muiTheme';
import { useDarkMode } from '@/utils/hooks';
import { AddressProvider } from '@/utils/hooksContext/useAddressContex';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import '../assets/css/Cookie.css';
import '../assets/scss/styles.scss';
import 'react-toastify/dist/ReactToastify.css';

interface CustomAppProps extends AppProps {
  Component: NextComponentType & {
    public?: boolean;
    unsigned?: boolean;
    forceLightMode?: boolean;
    forceUnmount?: boolean;
  };
  emotionCache: EmotionCache;
  pageProps: SSRConfig & { session: Session };
}

export const ThemeContext = createContext(null);
export const RouterContext = createContext('/');

function getSelectedTheme(isDarkMode: boolean) {
  return isDarkMode ? DarkSMATMuiTheme : theme;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { session, ...pageProps }
}: CustomAppProps) {
  const [loaded, setLoaded] = useState(false);
  const { isReady, pathname } = useRouter();
  const [isDarkMode, setDarkMode] = useDarkMode();

  useEffect(() => {
    if (isReady) {
      setLoaded(true);
    }
  }, [isReady]);

  const client = CreateWagmiClient(isDarkMode);

  if (!loaded) return null;

  return (
    <CacheProvider value={emotionCache}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={
            Component.forceLightMode ? theme : getSelectedTheme(isDarkMode)
          }
        >
          <CssBaseline>
            {/* @ts-expect-error wontfix */}
            <ThemeContext.Provider value={[isDarkMode, setDarkMode]}>
              <Cookie />
              <NextNProgress color="#178097" nonce="my-nonce" />
              <SessionProvider session={session}>
                <WagmiConfig config={client}>
                  <AddressProvider>
                    <RecoilRoot>
                      <Component key={pathname} {...pageProps} />
                    </RecoilRoot>
                  </AddressProvider>
                </WagmiConfig>
              </SessionProvider>

              <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar={false}
                theme={isDarkMode ? 'dark' : 'light'}
                draggable={false}
                // @ts-expect-error
                pauseOnVisibilityChange
                closeOnClick
                pauseOnHover
              />
              <GoSquared />
            </ThemeContext.Provider>
          </CssBaseline>
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  );
}

export default appWithTranslation(MyApp);
