import { ReactNode, SyntheticEvent } from 'react';
import { DropzoneState } from 'react-dropzone';
import { ADMIN_SALE_INFORMATION_INPUTS } from '@/containers/Admin/utils';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Box, Button, Card, Typography } from '@mui/material';
import { styled } from '@mui/system';

const getColor = (props, theme) => {
  if (props.isDragAccept) {
    return theme.palette.success.main;
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#131788';
  }
  return '#178097';
};

const DropZoneContainer = styled(Card)(
  ({ theme, ...props }) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-color: ${getColor(props, theme)};
  border-style: dashed;
  padding: unset;
  outline: none;
  transition: border 0.24s ease-in-out;
`
);

interface DropzoneBaseProps {
  children: ReactNode;
  methods: DropzoneState;
  label: string;
  image: boolean;
  step?: ADMIN_SALE_INFORMATION_INPUTS;
  onClear: (e: SyntheticEvent<HTMLButtonElement>) => void;
  name?: string;
}

export default function DropzoneBase({
  children,
  methods,
  label = 'file to upload',
  image,
  step,
  onClear,
  name
}: DropzoneBaseProps) {
  const { getRootProps, isFocused, isDragAccept, isDragReject } = methods;
  const file = methods?.acceptedFiles[0];

  const Icon = (props) => {
    if (file) return <HighlightOffIcon {...props} color="error" />;
    return image ? (
      <AddPhotoAlternateIcon {...props} />
    ) : (
      <NoteAddIcon {...props} />
    );
  };

  return (
    <DropZoneContainer
      {...getRootProps({ isFocused, isDragAccept, isDragReject })}
    >
      <Button
        fullWidth
        variant={'text'}
        sx={{
          height: '100%',
          padding: '2rem',
          gap: '.5rem',
          display: 'flex'
        }}
        onClick={file && onClear}
        endIcon={
          <Icon fontSize="large" sx={{ height: '2rem', width: '2rem' }} />
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            textAlign: 'left',
            textTransform: 'none'
          }}
        >
          <Typography
            component={'label'}
            htmlFor={`file-${step}-${name}`}
            id={`label-${step}.${name}`}
            variant={'body1'}
            sx={{
              textOverflow: 'ellipsis',
              maxWidth: { xs: 240, sm: 270, md: 310 },
              overflow: 'hidden'
            }}
          >
            {label}
          </Typography>
          <Typography sx={{ color: 'common.text' }} variant={'caption'}>
            {file ? 'Click to remove' : 'Drop file or click here'}
          </Typography>
        </Box>
      </Button>
      {children}
    </DropZoneContainer>
  );
}
