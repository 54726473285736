import { Theme } from '@mui/material';

const styles = {
  root: {
    borderRadius: '10px'
  },
  title: {},
  login: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '0.5rem 1rem',
    color: ({ palette }: Theme) => palette.primary.main
  },
  subtitle: {
    paddingBottom: '0.5rem'
  }
};

const bankTransferStyles = {
  root: {
    p: '2rem',
    borderRadius: '10px'
  },
  title: {
    textAlign: 'center',
    pb: '1rem',
    color: ({ palette }: Theme) => palette.primary.main
  },
  subtitle: {
    paddingBottom: '0.5rem'
  }
};

export { styles, bankTransferStyles };
