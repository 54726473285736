import { useBeforeUnload } from '@/utils/hooks';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { styles } from './styles';
import { getShortAddress } from '@/utils';
import { Paragraph } from '../Typography/CommonTitles';

function CryptoWarning({
  onClose,
  address
}: {
  onClose: () => void;
  address: string;
}) {
  useBeforeUnload();

  return (
    <Card sx={styles.root}>
      <Box sx={styles.login}>
        <Typography variant="subtitle1" color="primary.main">
          Pending Transaction
        </Typography>
        <IconButton color={'primary'} sx={{ boxShadow: 0 }} onClick={onClose}>
          <CloseIcon width={24} />
        </IconButton>
      </Box>
      <Divider />
      <Stack spacing={1} sx={{ p: '1rem 2rem 2rem 2rem' }}>
        <Paragraph>
          Transaction sent to your wallet. Please check that receiving address
          matches:
        </Paragraph>
        <Box component={'b'} sx={{ display: 'block', textAlign: 'center' }}>
          {getShortAddress(address)}
        </Box>
        <Paragraph>
          Please do not close or refresh your screen unless you have either
          confirmed or canceled the ongoing transaction.
        </Paragraph>
      </Stack>
    </Card>
  );
}

export default CryptoWarning;
