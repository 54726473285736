import React, { SyntheticEvent, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Control, Controller, FieldError } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MAX_FILE_SIZE } from '@/common/config';
import { ADMIN_SALE_INFORMATION_INPUTS } from '@/containers/Admin/utils';

import { useToggle } from '@/utils/hooks';
import {
  FormControl,
  FormHelperText,
  SxProps,
  TextFieldVariants
} from '@mui/material';
import CropImageDialog from '../Modals/CropImageDialog';
import DropzoneBase from './DropZoneBase';

export type DropzoneFileTypes = Array<keyof FileTypesObject>;

interface DropzoneProps {
  control: Control<any, any>;
  name: string;
  label: string;
  accept?: DropzoneFileTypes;
  onChange?: (
    name: any,
    file?: File | null,
    reason?: 'upload' | 'clear'
  ) => void;
  onClear?: () => void;
  watch?: any;
  defaultValue?: string;
  error?: FieldError | boolean;
  variant?: TextFieldVariants;
  sx?: SxProps;
  // value: string;
  step?: ADMIN_SALE_INFORMATION_INPUTS;
  helperText?: string;
  deferUpload?: boolean;
  register?: any;
}

export const ACCEPT_IMAGES = '.png, .jpg, .jpeg';
export const ACCEPT_DOCUMENTS = '.doc, .docx, .pdf';

interface FileTypesObject {
  'image/*': string[];
  'text/*': string[];
  'video/*': string[];
  'audio/*': string[];
  'application/*': string[];
  'application/pdf': string[];
}

const getAcceptedFiles = (accept?: DropzoneFileTypes) => {
  if (!accept) return;
  const mapping = {
    'image/*': ACCEPT_IMAGES.split(',').map((el) => el.trim()),
    'text/*': ACCEPT_DOCUMENTS.split(',').map((el) => el.trim()),
    'video/*': [],
    'audio/*': [],
    'application/*': ['.pptx', '.ppt', '.pdf', 'doc', 'docx', '.txt'],
    'application/pdf': ['.pdf']
  };

  return accept.reduce((agg, el) => {
    agg[el] = mapping[el];
    return agg;
  }, {} as Accept);
};

const getAcceptedFormatLabel = (accept?: Accept): string => {
  if (!accept) return '';
  const mapping = {
    'image/*': ACCEPT_IMAGES,
    'text/*': ACCEPT_DOCUMENTS,
    'video/*': 'Videos',
    'audio/*': 'Audio',
    'application/*': ACCEPT_DOCUMENTS,
    'application/pdf': '.pdf'
  };

  return Object.keys(accept)
    .reduce((agg, el) => {
      agg.push(mapping[el]);
      return agg;
    }, [] as string[])
    .join(', ');
};

const initialValue = 'Drop file to upload';

const DropzoneTextfield = (props: DropzoneProps) => {
  const { deferUpload = true, control } = props;
  const accept = getAcceptedFiles(props.accept);
  const step = props?.step || props.name.split('.')?.[0];
  const name = props?.name?.split('.')?.[1] || props?.name;

  const [placeholder, setPlaceholder] = useState<string>(
    props.label || initialValue
  );
  const { on: openDialog, toggle } = useToggle();
  const methods = useDropzone({
    maxFiles: 1,
    accept,
    maxSize: MAX_FILE_SIZE,
    multiple: false,
    onDropAccepted(files) {
      const file = files[0];
      setPlaceholder(file?.name || 'file uploaded');
      if (file.type?.includes('image')) {
        toggle(true);
        return;
      }
      props.onChange?.(name || props.name, file, 'upload');
    },
    onDropRejected(fileRejection) {
      // fireErrorToast(fileRejection[0].errors[0].message, pathname);
      toast.error(fileRejection[0].errors[0].message);
    }
  });

  const handleConfirmImageCrop = (imageName: string, file?: File) => {
    props.onChange?.(imageName, file, 'upload');
    toggle(false);
  };

  const handleClearInput = (e?: SyntheticEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    props.onChange?.(name, undefined, 'clear');
    methods.acceptedFiles.length = 0;
    if (methods?.inputRef?.current) {
      methods.inputRef.current.value = '';
    }
    setPlaceholder(props.label || initialValue);
    props.onClear?.();
  };

  const handleCloseImageCrop = () => {
    handleClearInput();
    toggle(false);
  };

  const userReadableAcceptedFormats = getAcceptedFormatLabel(accept);
  return (
    <FormControl
      sx={{ width: '100%', minHeight: 60, ...props.sx }}
      variant={props.variant}
    >
      <>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <DropzoneBase
                image={!userReadableAcceptedFormats?.includes('pdf')}
                methods={methods}
                label={placeholder}
                onClear={handleClearInput}
                step={step as ADMIN_SALE_INFORMATION_INPUTS}
                {...field}
              >
                <input
                  data-testid={`file-${props.name}`}
                  id={`file-${step}-${name}`}
                  name={name}
                  {...methods.getInputProps()}
                />
              </DropzoneBase>
              <FormHelperText id={`${step}-${props.label}-helper-text`}>
                {/* @ts-expect-error */}
                {props.error?.message ||
                  props.helperText ||
                  `Accepted format: ${userReadableAcceptedFormats} | Maximum size: 10 MB`}
              </FormHelperText>
            </>
          )}
        />
        {openDialog && (
          <CropImageDialog
            file={methods?.acceptedFiles[0]}
            name={name}
            onClose={handleCloseImageCrop}
            onConfirm={handleConfirmImageCrop}
            deferUpload={deferUpload}
          />
        )}
      </>
    </FormControl>
  );
};

export default DropzoneTextfield;
