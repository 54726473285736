import React, { FC, ReactNode, useState } from 'react';
import { useSession } from 'next-auth/react';
import { RegisterOptions, UseFormRegisterReturn } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { FOP } from '@prisma/client';

interface ButtonGroupProps {
  buttons: Array<{ value: string | number; customIcon: ReactNode }>;
  onClick: (name: string, value: string | number) => void;
  name: string;
  value?: string | number;
  register?: (name: string, options?: RegisterOptions) => UseFormRegisterReturn;
  flag?: boolean;
  fopRestriction?: string;
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
  value,
  buttons,
  onClick,
  name,
  register,
  flag,
  fopRestriction
}) => {
  const { data: session } = useSession();
  const isSiwe = session?.user?.isSiwe;
  const [clickedId, setClickedId] = useState<string | number>(
    value !== undefined ? value : isSiwe ? FOP.CRYPTO : FOP.TRANSFER
  );
  const handleButtonChange = (value: string | number) => {
    setClickedId(value);
    if (onClick) onClick(name, value);
  };

  return (
    <>
      <input
        type="hidden"
        {...(register &&
          register(name, { required: 'Form of payment required' }))}
      />
      {buttons.map(({ customIcon, value }) =>
        (!flag && value === fopRestriction) ||
        (isSiwe && value === FOP.TRANSFER) ? null : (
          <Button
            sx={{
              display: 'flex',
              flex: '1 0',
              gap: '0.3rem',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6px',
              boxShadow: 2,
              opacity: 1
            }}
            key={value}
            onClick={() => handleButtonChange(value)}
            variant={`${value === clickedId ? 'contained' : 'text'}`}
          >
            <Box sx={{ gap: '.5rem', display: 'flex' }}>
              <div>{customIcon}</div>
              <p>{value}</p>
            </Box>
          </Button>
        )
      )}
    </>
  );
};
