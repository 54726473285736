import React from 'react';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  Card,
  Divider,
  Fade,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/system';
import copyToClipboard from '../../utils/copyToClipboard';
import { bankTransferStyles } from './styles';
import { IntlNumberFormat } from '@/utils/FormatNumber';
import currencyJs from 'currency.js';

const styles = bankTransferStyles;

const BankTransfer = ({ onClose, value, currency, onConfirm }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  if (!value || Number.isNaN(value)) {
    toast.error(
      'Unexpected error, please refresh page or contact administrator'
    );
    return null;
  }

  const formattedValue = IntlNumberFormat(
    currencyJs(value, { precision: 2 }).value,
    currency,
    2
  );
  return (
    <Fade in={true} timeout={1000}>
      <Card sx={styles.root}>
        <Typography variant="h4" sx={styles.title}>
          Bank Transfer
        </Typography>
        <Typography variant={'subtitle2'}></Typography>
        <Typography
          variant="subtitle1"
          sx={{ ...styles.subtitle, textAlign: 'center' }}
        >
          Smat S.A{' '}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          {' '}
          Rue de l'Avenir 23 - 2800 Délemont - Switzerland
        </Typography>
        {dataTransfer.map(({ subtitle, iban, bic }) => {
          return (
            <>
              <Typography variant="subtitle1" sx={{ pt: '1rem' }}>
                {subtitle}
              </Typography>
              <Divider sx={{ my: '0.5rem' }} />
              <Stack spacing={1}>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography sx={{ flex: 1 }} variant="body2">
                    IBAN:{iban}
                  </Typography>

                  <IconButton
                    onClick={() =>
                      copyToClipboard({
                        title: `${iban?.trim()}`,
                        text: `${iban?.trim()}`,
                        breakpoint: isMobile
                      })
                    }
                  >
                    <ContentCopyIcon stroke={'#178097'} fontSize={'small'} />
                  </IconButton>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography sx={{ flex: 1 }} variant="body2">
                    BIC:{bic}
                  </Typography>
                  <IconButton
                    onClick={() =>
                      copyToClipboard({
                        title: `${bic?.trim()}`,
                        text: `${bic?.trim()}`,
                        breakpoint: isMobile
                      })
                    }
                  >
                    <ContentCopyIcon stroke={'#178097'} fontSize={'small'} />
                  </IconButton>
                </Box>
              </Stack>
            </>
          );
        })}
        <Box py={1} display={'flex'} gap={0.5}>
          <Typography variant="subtitle1" color={'#178097'}>
            TO PAY:{' '}
          </Typography>
          <Typography variant="subtitle1">{formattedValue}</Typography>
        </Box>
        <Box sx={{ pb: '1rem' }}>
          <Typography
            variant="body2"
            fontSize={'0.8rem'}
            sx={{
              textAlign: 'center',
              color: ({ palette }: Theme) => palette.primary.main
            }}
          >
            ( This transaction will be canceled in 6 hours if payment is not
            confirmed )
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Button
            sx={{ flex: 1 }}
            variant={'outlined'}
            fullWidth
            onClick={onClose}
          >
            Close
          </Button>
          <Button variant={'contained'} fullWidth onClick={onConfirm}>
            Confirm Payment
          </Button>
        </Box>
      </Card>
    </Fade>
  );
};

const dataTransfer = [
  {
    subtitle: 'For CHF',
    iban: ' CH71 8080 8002 6358 1785 0 ',
    bic: ' RAIFCH22'
  },
  {
    subtitle: 'For EUR',
    iban: ' GB38REVO00996909546892 ',
    bic: ' REVOGB21'
  }
];

export default BankTransfer;
