import { SyntheticEvent, forwardRef } from 'react';
import Image from 'next/legacy/image';
import { getAssets } from '@/services/minio.service';
import { GetLoginImagesReturn } from '@/utils/images';
import { Box, SxProps } from '@mui/material';

// import { trackGA4Event } from '../Analytics';

interface LogoProps {
  images?: GetLoginImagesReturn;
  black?: boolean;
  sx?: SxProps;
  onClick?: (e?: SyntheticEvent<HTMLDivElement>) => void;
  href?: string;
}

export const SmatLogo = forwardRef(function Logo(
  { images = undefined, black = true, sx = {}, onClick, href }: LogoProps,
  ref
) {
  const props = images
    ? { ...images[black ? 'logoBlack' : 'logoWhite'] }
    : { src: getAssets(black ? 'v2/v2-logo-tp.webp' : 'v2/v2-logo.webp') };
  const handleLogoClick = (e) => {
    // trackGA4Event({
    //   category: GA4_CUSTOM_EVENTS.Navigation,
    //   action: GA4_EVENT_NAMES[GA4_CUSTOM_EVENTS.Navigation].Home,
    // });
    onClick?.(e);
  };
  return (
    <Box
      onClick={handleLogoClick}
      component={'a'}
      ref={ref}
      sx={{
        width: { xs: '12rem', md: '24rem' },
        height: { xs: '6rem', md: '9rem' },
        ...sx
      }}
      {...(href && { href })}
    >
      <Image
        alt="Smat logo"
        priority
        {...props}
        placeholder={images ? 'blur' : undefined}
        layout="fixed"
        height={48}
        width={160}
      />
    </Box>
  );
});

export const SmatIsoLogo = forwardRef(function Logo(
  {
    onClick = undefined,
    images = undefined,
    sx = {}
  }: Omit<LogoProps, 'black'>,
  ref
) {
  const props = images
    ? { ...images.isologo }
    : { src: getAssets('v2/v2-isologo.webp') };
  const handleLogoClick = (e) => {
    // trackGA4Event({
    //   category: GA4_CUSTOM_EVENTS.Navigation,
    //   action: GA4_EVENT_NAMES[GA4_CUSTOM_EVENTS.Navigation].Home,
    // });
    onClick?.(e);
  };
  return (
    <Box
      onClick={handleLogoClick}
      ref={ref}
      component={'a'}
      sx={{
        bgcolor: 'common.white',
        width: '100px',
        borderRadius: '30px',
        padding: '10px 10px',
        ...sx
      }}
    >
      <Image
        layout="intrinsic"
        width={678}
        height={583}
        alt="Smat logo"
        priority
        {...props}
        placeholder={images ? 'blur' : undefined}
      />
    </Box>
  );
});
