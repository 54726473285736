import React from 'react';

// import { ResponsiveBar } from '@nivo/bar';

// const keys = ['amount'];
const Wrapper = (props) => (
  <div {...props} style={{ height: '150px', width: '100%' }} />
);
// const defaultData = [
//   {
//     month: 'May-22',
//     token: 'SMAT',
//     amountInCHF: 19,
//     amountColor: '#1c7391',
//     amount: 190,
//   },
//   {
//     month: 'Jun-22',
//     token: 'SMAT',
//     amount: 144,
//     amountColor: '#1c7391',
//     amountInCHF: 19,
//   },
//   {
//     month: 'Jul-22',
//     token: 'SMAT',
//     amount: 107,
//     amountColor: '#1c7391',
//     amountInCHF: 19,
//   },
//   {
//     month: 'Aug-22',
//     token: 'SMAT',
//     amount: 34,
//     amountColor: '#1c7391',
//     amountInCHF: 19,
//   },
//   {
//     month: 'Sep-22',
//     token: 'SMAT',
//     amount: 55,
//     amountColor: '#1c7391',
//     amountInCHF: 19,
//   },
// ];
export default function TransactionsContent() {
  return (
    <Wrapper>
      {/* <ResponsiveBar
        data={data || []}
        keys={keys}
        indexBy="month"
        margin={{ top: 50, right: 50, bottom: 30, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // colors={{ scheme: 'nivo' }}
        colors={({ id, data }) => String(data[`${id}Color`])}
        borderColor={'#1c7391'}
        borderWidth={1}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Amount',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        role="application"
        label={(d) => `${d.value} ${d.data.token}`}
        labelTextColor={'#FFF'}
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) {
          return (
            e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
          );
        }}
        tooltip={({ id, data, value, color, ...rest }) => (
          <div
            style={{
              padding: 12,
              color: '#00bdd5',
              background: '#2c3e50',
            }}
          >
            <>
              <strong>{value} SMAT</strong>
              <br />
              <i> =~ {data.amountInCHF} CHF</i>
            </>
          </div>
        )}
      /> */}
    </Wrapper>
  );
}
