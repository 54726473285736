export const IS_CI =
  process.env.NEXT_PUBLIC_CI === 'true' ||
  !!process.env.NEXT_PUBLIC_GITHUB_ACTIONS;

export const IS_STAGE_SERVER = process.env.IS_STAGE === 'true';
export const IS_PRODUCTION =
  process.env.NODE_ENV === 'production' && !IS_STAGE_SERVER;
export const IS_DEVELOPMENT =
  (process.env.NODE_ENV !== 'production' ||
    process.env.NEXT_PUBLIC_IS_DEVELOPMENT === 'true') &&
  !IS_STAGE_SERVER;

export const IS_TEST = process.env.NODE_ENV === 'test';

export const MAX_FILE_SIZE = 10000000;
export const MAX_ALLOWANCE_WITHOUT_KYC = 5000;
