import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const MyTooltip = ({
  label,
  classes = '',
  classesMain = '',
  style = {},
  triangle = true
}) => {
  return (
    <div className={`tooltip ${classesMain}`}>
      <div className={`tooltip__container ${classes}`}>{label}</div>
      {triangle && <div className="tooltip__triangle" style={style}></div>}
    </div>
  );
};

const CustomStyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
    background: 'linear-gradient(150deg, #1d2838 35%, rgba(21, 29, 41, 0.85))',
    color: '#FFF',
    fontSize: '.85rem',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    padding: '.5rem 1rem',
    margin: '1rem 0rem',
    position: 'relative'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#1d2838'
  }
});

export const CustomTooltip = ({ label, children, ...props }) => {
  return (
    <CustomStyledTooltip
      title={label}
      placement={props.placement || 'top'}
      arrow
      {...props}
    >
      {children}
    </CustomStyledTooltip>
  );
};

export default MyTooltip;
