import { PaletteMode } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
  ACCENT_GRADIENT,
  BLACK_GRADIENT,
  PRIMARY_GRADIENT,
  SECONDARY_GRADIENT,
  TYPOGRAPHY_VARIANTS
} from './customizations';

const DarkSmatTheme = {
  typography: {
    fontFamily: [
      'Sora',
      'Overpass',
      'Roboto Flex',
      'system-ui',
      'sans-serif'
    ].join(','),
    ...TYPOGRAPHY_VARIANTS
  },
  palette: {
    mode: 'dark' as PaletteMode,
    common: {
      blackish: 'rgba(216, 223, 233, 0.21)',
      overlay: 'rgba(0, 0, 0, 0.6)',
      gradient: BLACK_GRADIENT,
      gray: '#6E6E6E',
      tableGray: '#B0B3B7',
      lightGray: '#E6E6E6',
      disabled: 'rgb(0,0,0,0.26)',
      bg: 'rgba(255, 255, 255, 0.05)',
      helperText: 'rgba(255, 255, 255, 0.7)',
      text: '#616d7e',
      white: '#fff',
      black: '#000',
      bgCard: '#2E2E2E',
      icons: '#2C3E50'
    },
    text: {
      primary: '#fff'
    },
    primary: {
      light: '#EAF4F3',
      main: '#178097',
      gradient: PRIMARY_GRADIENT
    },
    secondary: {
      main: '#131788',
      gradient: SECONDARY_GRADIENT
    },

    accent: {
      main: '#67e1fc',
      secondary: '#6166EC',
      gradient: ACCENT_GRADIENT
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          padding: '1rem 2rem'
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Sora',
          fontWeight: 500,
          letterSpacing: 1.2,
          fontSize: '1rem',
          padding: '0.5rem 2.5rem',
          textTransform: 'capitalize' as const,
          maxHeight: '3rem',
          borderRadius: '5px',
          backgroundColor: '#178097',
          color: '#FFF',
          transition: 'all 0.3s ease-in-out',
          whiteSpace: 'nowrap' as const,
          '&:hover': {
            backgroundColor: '#131788',
            borderColor: '#131788'
          }
        },
        loadingIndicator: {
          color: '#67e1fc'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Sora',
          fontWeight: 600,
          letterSpacing: 1.2,
          fontSize: '1rem',
          padding: '0.5rem 2.5rem',
          textTransform: 'capitalize' as const,
          maxHeight: '3rem',
          borderRadius: '5px',
          transition: 'all 0.3s ease-in-out',
          whiteSpace: 'nowrap' as const,
          '@media (max-width: 600px)': {
            padding: '0.5rem 1rem'
          },
          '@media (max-width: 400px)': {
            padding: '0.5rem'
          }
        },
        contained: {
          '&:hover': {
            transform: 'scale(0.94)',
            backgroundColor: '#131788'
          }
        },
        textSizeMedium: {
          '&:hover': {
            color: '#fff',
            transform: 'scale(0.94)',
            backgroundColor: 'unset'
          }
        },
        outlinedPrimary: {
          color: '#fff !important',
          borderColor: '#fff !important',
          backgroundColor: 'unset',
          '&:hover': {
            backgroundColor: '#272727',
            transform: 'scale(0.94)'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1em',
          color: '#fff',
          backgroundColor: 'transparent!important'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Sora',
          fontWeight: 500,
          letterSpacing: 1.2,
          fontSize: '1rem',
          padding: '0.5rem 0.75rem',
          color: '#fff',
          alignItems: 'center',
          display: 'flex',
          transition: 'color 300ms ease-in-out',
          '&:hover': {
            backgroundColor: 'rgba(23, 128, 151, 0.04)',
            color: '#178097'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    // MuiDataGrid: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#2E2E2E',
    //     },
    //     cellContent: {
    //       color: '#fff',
    //     },
    //   },
    // },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: '1rem!important',
          borderRadius: '10px',
          '&:before': {
            display: 'none'
          },
          '&:first-of-type': {
            borderTopLeftRadius: '10px!important',
            borderTopRightRadius: '10px!important'
          },
          '&:last-of-type': {
            borderBottomLeftRadius: '10px!important',
            borderBottomRightRadius: '10px!important'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: '0rem!important'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: '#fff'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          color: '#fff'
        }
      }
    },
    MuiSelected: {
      styleOverrides: {
        root: { minWidth: '100% !important', width: 'fit-content !important' }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#2E2E2E',
          border: '1px solid rgba(128, 128, 128, 0.3) !important',
          boxShadow: '0px 3px 6px rgba(39, 45, 59, 0.33) !important',
          fontFamily: 'Sora !important',
          fontSize: '13px !important',
          borderRadius: '10px !important',
          minHeight: '322px !important',
          maxWidth: 'unset !important'
        },

        cellContent: {
          color: '#fff'
        },
        renderingZone: {
          width: '100% !important'
        },
        window: {
          '::-webkit-scrollbar': {
            width: '6px',
            height: '6px'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey',
            borderRadius: '10px'
          }
        },
        footer: {
          justifyContent: 'center !important'
        },
        iconSeparator: {
          display: 'none !important'
        },
        viewport: {
          overflow: 'visible !important'
        },
        columnHeader: {
          padding: '0 10px !important',
          display: 'flex !important',
          position: 'relative !important',
          alignItems: 'center !important',
          flexGrow: 1
        },
        columnHeaderWrapper: {
          justifyContent: 'space-between !important'
        },
        columnHeaderTitle: {
          color: '#6e6e6e'
        },
        columnHeaderTitleContainer: {
          padding: '0px !important'
        },
        cell: {
          overflow: 'hidden !important',
          whiteSpace: 'nowrap !important',
          border: '0px solid rgba(224, 224, 224, 1) !important',
          textOverflow: 'ellipsis !important',
          color: '#6e6e6e',
          fontWeight: 500,
          outline: 'none !important'
        },
        lastColumn: {
          position: 'relative !important'
        },
        row: {
          position: 'relative !important',
          overflowY: 'visible !important',
          overflowX: 'visible !important',
          margin: '0 !important',
          padding: '0 !important',
          width: '100% !important',
          justifyContent: 'space-between !important'
        },

        columnHeaderSortable: {
          flexGrow: 1,
          maxWidth: 'unset !important',
          width: 'unset !important'
        },
        cellCenter: {
          overflow: 'visible !important'
        },
        lastColumnCell: {
          display: 'none !important'
        },
        windowAuto: {
          overflowX: 'auto !important'
        }
      }
    }
  }
};

const DarkSMATMuiTheme = createTheme(DarkSmatTheme);

export default responsiveFontSizes(DarkSMATMuiTheme);
