import React from 'react';
import { useRouter } from 'next/router';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Typography
} from '@mui/material';

import { styles } from './styles';
import { SaleTransactions } from '@prisma/client';

function PendingTransaction({
  onClose,
  transaction
}: {
  onClose: () => void;
  transaction?: SaleTransactions;
}) {
  const { push } = useRouter();
  const fop = transaction?.formOfPayment;

  return (
    <Card sx={styles.root}>
      <Box sx={styles.login}>
        <Typography variant="subtitle1">Pending Transaction</Typography>
        <IconButton color={'primary'} sx={{ boxShadow: 0 }} onClick={onClose}>
          <CloseIcon width={24} />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ p: '1rem 2rem 2rem 2rem' }}>
        <Typography
          align={'justify'}
          variant={'body1'}
          sx={{ lineHeight: 1.6, py: '1rem' }}
        >
          We found a pending transaction in your account, please go to the
          transactions page to confirm or cancel it before proceeding to make
          another purchase. Thank you!
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Button
            sx={{ flex: 1 }}
            variant={'outlined'}
            fullWidth
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant={'contained'}
            fullWidth
            onClick={() =>
              push({
                pathname: '/transactions',
                query: fop ? { type: fop } : undefined
              })
            }
          >
            Go to transactions
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

export default PendingTransaction;
