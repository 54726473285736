import React from 'react';
import { CLIENT_FEATURES } from '@/common/config/features';
import { ButtonGroup } from '@/components/ButtonGroup';
import Polygon from '@/components/icons/Polygon.icon';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, Typography } from '@mui/material';
import { FOP } from '@prisma/client';
import { InputLabel } from '..';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormInvestSchema } from '@/common/types/interfaces';

function PaymentMethod({
  handleFormChange,
  register,
  errors
}: {
  handleFormChange: (name: 'formOfPayment', value: FOP) => Promise<void>;
  register: UseFormRegister<FormInvestSchema>;
  errors: FieldErrors<FormInvestSchema>;
}) {
  return (
    <>
      <InputLabel
        id={'formOfPayment'}
        translateNamespace={'payment'}
        content={'Choose the payment method'}
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'space-around', gap: '1rem' }}
      >
        <ButtonGroup
          buttons={buttonsData}
          onClick={handleFormChange}
          name={'formOfPayment'}
          register={register}
          flag={CLIENT_FEATURES.Ramp}
          fopRestriction={FOP.CARD}
        />
      </Box>
      <div>
        <Typography sx={{ color: '#e1534f' }}>
          {errors.formOfPayment && String(errors['formOfPayment']?.message)}
        </Typography>
      </div>
    </>
  );
}

const buttonsData = [
  { value: FOP.CRYPTO, customIcon: <Polygon /> },
  { value: FOP.CARD, customIcon: <CreditCardIcon /> },
  { value: FOP.TRANSFER, customIcon: <AccountBalanceIcon /> }
];

export default PaymentMethod;
