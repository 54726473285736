import React from 'react';
import { TextField, Typography } from '@mui/material';
import { InputLabel } from '..';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormInvestSchema } from '@/common/types/interfaces';

function AddressWallet({
  errors,
  register,
  isCryptoFOP,
  address
}: {
  errors: FieldErrors<FormInvestSchema>;
  register: UseFormRegister<FormInvestSchema>;
  isCryptoFOP: boolean;
  address?: `0x${string}`;
}) {
  const key = 'address';
  return (
    <>
      <InputLabel
        id={key}
        translateNamespace={'wallet'}
        content={'Enter an EVM compatible wallet, with format 0x...'}
      />

      <TextField
        key={key}
        type="text"
        variant={'filled'}
        error={!!errors['address']}
        value={isCryptoFOP && address ? address : null}
        disabled={isCryptoFOP && address ? true : false}
        {...register(key, { required: true })}
      />
      <Typography sx={{ color: '#e1534f' }}>
        {errors.address &&
          'Format should be an Ethereum compatible wallet address'}
      </Typography>
    </>
  );
}

export default AddressWallet;
