import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  Icon,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  InputLabel
} from '@mui/material';
import { Box } from '@mui/system';

const LOCALES = [
  { name: 'English', value: 'en' },
  { name: 'Français', value: 'fr' },
  { name: 'Español', value: 'es' }
];

export default function LanguageSelect({
  white = false,
  showIcon = true,
  shallow = false,
  drawer = false,
  sx = {}
}) {
  const { pathname, query, asPath, push } = useRouter();
  const { i18n } = useTranslation();

  const handleChangeLang = ({ target }) => {
    const {
      value: { value }
    } = target;
    if (!value) return;
    if (shallow) {
      i18n.changeLanguage(value);
    }
    push({ pathname, query }, asPath, { locale: value, shallow });
  };
  const isMobile = useMediaQuery('(max-width:600px)');

  if (!LOCALES?.length) return null;

  const labelId = `language-selector-label${drawer ? '-menu' : ''}`;
  return (
    <Box component={'span'} sx={{ ...sx }}>
      <InputLabel id={labelId} aria-label={labelId.split('-').join(' ')} />
      <Select
        sx={{
          '& .MuiSelect-select': {
            paddingRight: `${!isMobile && '0px !important'}`
          }
        }}
        labelId={labelId}
        id={`language-selector${drawer ? '-menu' : ''}`}
        value={LOCALES.find((lang) => lang.value === i18n.language)}
        onChange={handleChangeLang}
        variant={'standard'}
        disableUnderline
        IconComponent={!showIcon ? Icon : undefined}
        renderValue={({ value }) => {
          return (
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ color: white ? '#FFF' : '#000' }}
            >
              <Typography
                variant={'subtitle2'}
                align={'center'}
                sx={{ minWidth: { sm: 'unset', md: '7ch' } }}
              >
                {value}
              </Typography>
            </Box>
          );
        }}
      >
        {LOCALES.map((locale) => (
          /* @ts-expect-error */
          <MenuItem MenuItem key={locale.value} value={locale}>
            {locale.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
