import React from 'react';
import { Box, Card, CircularProgress } from '@mui/material';

const LoadingModal = () => {
  return (
    <Card
      sx={{
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '300px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <CircularProgress></CircularProgress>
      </Box>
    </Card>
  );
};

export default LoadingModal;
