import { useTransactionStatus } from '@/services/api.service';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Card,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { styles } from './styles';
import { TransactionModalTypes } from '@/common/enums';
import { Dispatch, SetStateAction } from 'react';

interface ContractProps {
  handleModal: (arg: TransactionModalTypes | null) => void;
  setContract: Dispatch<SetStateAction<string | null>>;
}

const Contract = ({ handleModal, setContract }: ContractProps) => {
  const { isLoading, data } = useTransactionStatus();

  const handleSign = () => {
    setContract(data?.urlSign || null);
    handleModal(null);
  };

  const loading = isLoading || !data?.urlSign;

  return (
    <Card sx={styles.root}>
      <Typography sx={{ pb: '1rem' }} variant="subtitle1">
        Before Proceeding with Your Purchase:
      </Typography>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1rem 0' }}>
        <List>
          {contractNotes.map((value, index) => (
            <ListItem
              disablePadding
              key={index}
              sx={{ display: 'flex', gap: '1rem' }}
            >
              <ListItemIcon sx={{ minWidth: 'unset' }}>
                <CheckSharpIcon color="primary" />
              </ListItemIcon>
              <ListItemText sx={{ flex: 1 }} primary={value} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box width="100%">
        <LoadingButton
          loading={loading}
          onClick={() => handleSign()}
          variant={'contained'}
          sx={{ width: '100%' }}
        >
          Sign Agreement
        </LoadingButton>
      </Box>
    </Card>
  );
};

const contractNotes = [
  ' This sale requires the buyer to sign a SAFT before buying tokens.',
  ' Ensure you sign an agreement as per the sellers terms. Click "Sign Agreement" to proceed.',
  ' Clicking "Sign Agreement" opens the contract for your signature.',
  ' Confirm your signature post-signing to finalize the agreement.'
];

export default Contract;
