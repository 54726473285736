import { ReactNode } from 'react';
import Image, { ImageProps } from 'next/legacy/image';
import { useRouter } from 'next/router';
import { Locales } from '@/common/enums';
// import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import {
  Box,
  Button,
  Card,
  IconButton,
  SxProps,
  Typography
} from '@mui/material';
import Twitter from '../icons/TwitterDash.icon';

//@ts-expect-error Incorrect extend
interface DashboardMediaProps extends ImageProps {
  children?: ReactNode | string;
  header?: ReactNode | string;
  content?: ReactNode | string;
  action?: ReactNode | string;
  sx?: SxProps;
}

const DashboardMediaCard: React.FC<DashboardMediaProps> = ({
  // children,
  src,
  header,
  content,
  action,
  sx,
  ...props
}) => {
  return (
    <Card
      sx={{
        p: '1.5rem',
        boxShadow: 3,
        borderRadius: '10px',
        minHeight: '11rem',
        height: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'center', sm: 'left' },
        gap: '1.5rem',
        bgcolor: 'common.bg',
        ...sx
      }}
    >
      {src && (
        <Box sx={{ '&:span': { borderRadius: '10px' } }}>
          <Image src={src} {...props} alt={''} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '1rem',
          gap: '1.5rem',
          alignItems: { xs: 'center', sm: 'start' }
        }}
      >
        {header || (
          <Typography variant="h6">
            Thank you for your interest in our SMAT Token
          </Typography>
        )}
        {content || <ContentText />}
        <Box sx={{ width: '100%' }}>{action || <MediaSection />}</Box>
      </Box>
    </Card>
  );
};

const MediaSection = () => {
  const { locale } = useRouter();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { xs: 'center', sm: 'left' },
          '& > span': {
            display: { xs: 'none', sm: 'flex' }
          }
        }}
      >
        <div>
          <Button
            aria-label="go to telegram"
            sx={buttonStyles}
            target="_blank"
            startIcon={<TelegramIcon />}
            href={
              locale === Locales.FR
                ? 'https://t.me/CommunauteSmat'
                : 'https://t.me/SmatCommunity'
            }
          >
            Join our Telegram
          </Button>
        </div>
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          {socialMedia.map(({ href, icon, label }) => {
            return (
              <a
                target={'_blank'}
                rel={'noreferrer noopener nofollow'}
                href={href}
                key={href}
              >
                <IconButton sx={iconButtonStyles} aria-label={label}>
                  {icon}
                </IconButton>
              </a>
            );
          })}

          {/* <Link passHref href={'https://medium.com/@Smat.io'}><IconButton sx={iconButtonStyles} aria-label="go to linkedin"></IconButton></Link> */}
        </Box>
      </Box>
      <Typography
        sx={{
          textAlign: 'right',
          color: '#5e636b',
          fontSize: '0.75rem !important',
          marginTop: '0.5rem'
        }}
      >
        Follow us!
      </Typography>
    </>
  );
};

const socialMedia = [
  // {
  //   href: 'https://www.youtube.com/channel/UCpXll_K2Bo30JfRKEpw4VCQ',
  //   icon: <YouTubeIcon />,
  //   label: 'go to youtube',
  // },
  {
    href: 'https://twitter.com/smat_io',
    icon: <Twitter color={'white'} />,
    label: 'go to twitter'
  },
  // {
  //   href: 'https://www.instagram.com/smat.io/',
  //   icon: <InstagramIcon />,
  //   label: 'go to instagram',
  // },
  {
    href: 'https://www.linkedin.com/company/smat-io/',
    icon: <LinkedInIcon />,
    label: 'go to linkedin'
  }
];

const buttonStyles = {
  color: '#fff',
  backgroundColor: '#178097',
  ':hover': { backgroundColor: 'secondary.main' },
  '>span': { display: { xs: 'none', sm: 'flex' } }
};

const iconButtonStyles = { ...buttonStyles, borderRadius: '5px' };

const ContentText = () => {
  return (
    <div>
      <Typography
        sx={{
          color: '#5e636b',
          fontSize: '1rem'
        }}
      >
        You may purchase SMAT Tokens if you follow the “Buy Token” page. Do you
        have some questions? You can find the answers to all your questions by
        simply chatting with us on our Telegram or our X. Invite your friends to
        join you in our Smat journey!
      </Typography>
    </div>
  );
};

export default DashboardMediaCard;
