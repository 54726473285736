import { useEffect, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import {
  confirmContractSignature,
  useTransactionStatus
} from '@/services/api.service';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Button, Fade, Box } from '@mui/material';
import { FOP } from '@prisma/client';

import { TransactionModalTypes } from '@/common/enums';
import { FormInvestSchema } from '@/common/types/interfaces';
interface ContractViewerProps {
  contractUrl: string;
  onClose: (arg: string | null) => void;
  write?: () => void;
  handleModal: (arg: TransactionModalTypes) => void;
  getValues?: UseFormGetValues<FormInvestSchema>;
  txType?: any;
}
const ContractViewer = ({
  contractUrl,
  onClose,
  write,
  handleModal,
  getValues,
  txType
}: ContractViewerProps) => {
  const [showViewer, setShowViewer] = useState(false);
  const { mutate } = useTransactionStatus();

  const isCrypto = txType === FOP.CRYPTO;

  const handleClose = () => {
    onClose(null);
  };

  useEffect(() => {
    const handleEvent = (e: MessageEvent) => {
      if (e.origin?.includes('adobesign.com')) {
        const payload = JSON.parse(e.data);
        if (payload?.type === 'ESIGN') {
          if (
            isCrypto ||
            (getValues && getValues().formOfPayment === FOP.CRYPTO)
          ) {
            handleModal(TransactionModalTypes.CryptoWarning);
            write?.();
            setShowViewer(true);
          } else {
            handleModal(TransactionModalTypes.ManualTransfer);
          }
          confirmContractSignature();
          mutate();
          handleClose();
        }
      }
    };

    window.addEventListener('message', handleEvent, false);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  useEffect(() => {
    if (showViewer) {
      write?.();
      setShowViewer(false);
    }
  }, [showViewer]);

  return (
    <Fade in={!!contractUrl} timeout={1000}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 50
        }}
      >
        <Card sx={{ width: '80%', height: '80%' }}>
          <CardMedia
            component="iframe"
            src={contractUrl}
            sx={{ width: '100%', height: '100%' }}
          />
        </Card>
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: 'white'
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Fade>
  );
};

export default ContractViewer;
