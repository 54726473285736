import { DateTime } from 'luxon';

export function dateFormatterInput(props) {
  const { date, format } = props;
  return DateTime.fromISO(date).toLocaleString(format);
}

export function DateFormatter(props) {
  DateTime;
  const { date, time = true, classes } = props;
  const dateTime = DateTime.fromISO(date);
  return (
    <div className={classes}>
      <p>{dateTime.toLocaleString()}</p>
      {time && <span>{dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}</span>}
    </div>
  );
}

export function DateFormatterWithHours(date) {
  const unformattedDate = DateTime.fromISO(date);
  const finalDates = `${unformattedDate.toLocaleString()} ${unformattedDate.toLocaleString(
    DateTime.TIME_24_SIMPLE
  )}`;
  return finalDates;
}

export const FormatDateToUTC = (date: string | Date): string => {
  if (typeof date === 'object') {
    return DateTime.fromJSDate(date, { zone: 'utc' }).toISO() || '';
  }
  return DateTime.fromISO(date, { zone: 'utc' }).toISO() || '';
};
