import { Box } from '@mui/material';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import MuiStepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';

export default function Stepper(props) {
  const { steps, icons, activeStep } = props;
  const classes = stepperCustomStyles;

  function ColorlibStepIcon(props) {
    const { active, completed } = props;
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Box sx={classes.root}>
      <MuiStepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((_, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Box>
  );
}

const stepperCustomStyles = {
  root: {
    width: '100%',
    overflowX: { xs: 'scroll', md: 'initial' },
    py: '2rem'
  },
  button: {
    marginRight: ({ theme }) => theme.spacing(1)
  },
  instructions: {
    marginTop: ({ theme }) => theme.spacing(1),
    marginBottom: ({ theme }) => theme.spacing(1)
  }
};

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#178097'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#178097'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 10,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme: { palette }, ownerState }) => ({
  backgroundColor: '#E6E6E6',
  fontSize: '10px',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 12,
  '& > svg': {
    stroke:
      palette.mode === 'dark' ? palette.common.black : palette.text.primary
  },
  ...(ownerState.active && {
    backgroundColor: '#178097',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    '& > svg': {
      stroke: palette.mode === 'dark' && palette.text.primary
    }
  }),
  ...(ownerState.completed && {
    backgroundColor: '#178097',
    '& > svg': {
      stroke: palette.mode === 'dark' && palette.text.primary
    }
  })
}));
