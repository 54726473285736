import { ReactElement, ReactNode, ReactSVGElement } from 'react';

interface ButtonNavProps {
  children: ReactNode;
  icon?: ReactNode | ReactSVGElement | ReactElement;
  isActive?: boolean;
  onClick?: () => void;
}

const ButtonNav = ({ children, icon, isActive, onClick }: ButtonNavProps) => {
  const active = isActive ? '--active' : '';
  return (
    <button className={`buttonNav${active}`} onClick={onClick}>
      <div className={`buttonNav__blob${active}`} />
      <div className={`buttonNav__children${active}`}>
        <p>{children}</p>
        {icon}
      </div>
    </button>
  );
};

export default ButtonNav;
