import { CLIENT_FEATURES } from '@/common/config/features';
import { SessionUser } from '@/common/types/next-auth';
import { Sale, SaleStatus } from '@prisma/client';

export const isSaleStatusOpen = (status?: SaleStatus): boolean => {
  return status === SaleStatus.OPEN && CLIENT_FEATURES.Status;
};

export const isSaleCreatedByCurrentUser = (sale?: Sale, user?: SessionUser) => {
  const createdBy = sale?.createdBy;
  if (!createdBy || !user) return null;
  return user?.sub === createdBy;
};
