import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue
} from 'react-hook-form';
import NumberFormat, {
  NumberFormatValues,
  SourceInfo
} from 'react-number-format';
import { getValueLimit } from '@/containers/utils/numberformatter/DisplayNumberFormat';

import { Box, FilledInput, FormControl, Typography } from '@mui/material';
import { InputLabel } from '..';
import { ActiveSale } from '@/common/types/sales';
import { FormInvestSchema } from '@/common/types/interfaces';

import amountCalculator from '@/services/pricefeeds/amount.service';
import { fireErrorToast } from '@/utils';
import currency from 'currency.js';
import { getNetworkToken } from '@/services/crypto/config';
import { useNetwork } from 'wagmi';

function Quantity({
  control,
  sale,
  pricePerUnit,
  getValues,
  errors,
  setValue
}: {
  control: Control<FormInvestSchema>;
  sale: ActiveSale;
  pricePerUnit: string | null;
  getValues: UseFormGetValues<FormInvestSchema>;
  errors: FieldErrors<FormInvestSchema>;
  setValue: UseFormSetValue<FormInvestSchema>;
}) {
  const { chain } = useNetwork();
  const MAX_BUY_ALLOWANCE = sale?.maximumTokenBuyPerUser
    ? sale?.maximumTokenBuyPerUser
    : sale?.availableTokenQuantity;

  const handleValueChange = async (v: NumberFormatValues, _s: SourceInfo) => {
    const quantity = v.value;
    try {
      const values = getValues();
      const isCryptoFOP = values.formOfPayment === 'CRYPTO';

      const token = getNetworkToken(chain, values.paymentCurrency);

      const { amount, bigNumber } = await amountCalculator.calculateAmountToPay(
        {
          currency: values.paymentCurrency,
          quantity: quantity,
          sale: sale,
          pricePerUnit,
          tokenDecimals: token?.decimals
        }
      );
      if (!amount) {
        throw new Error(
          'Error calculating amount, please refresh and try again'
        );
      }
      setValue('quantity', quantity);
      setValue(
        'paymentAmount',
        isCryptoFOP ? amount : currency(amount, { precision: 2 }).toString()
      );
      setValue('paymentAmountCrypto', bigNumber || '');
    } catch (e) {
      fireErrorToast(e);
    }
  };

  return (
    <>
      <InputLabel
        id={'quantity'}
        content={`Enter the amount. Min tokens: ${sale?.minimumTokenBuyPerUser} ${
          sale?.maximumTokenBuyPerUser
            ? `/ Max tokens: ${sale?.maximumTokenBuyPerUser}`
            : ''
        }`}
        translateNamespace={'amount'}
      />
      <Box>
        <FormControl variant="filled" fullWidth>
          <Controller
            control={control}
            name={'quantity'}
            rules={{ required: true }}
            render={({ field: { onBlur, value } }) => (
              <>
                <NumberFormat
                  isAllowed={
                    MAX_BUY_ALLOWANCE
                      ? getValueLimit(MAX_BUY_ALLOWANCE)
                      : undefined
                  }
                  customInput={FilledInput}
                  value={value}
                  onValueChange={handleValueChange}
                  error={!!errors['quantity']}
                  onBlur={onBlur}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={0}
                  // {...props}
                />
              </>
            )}
          />
        </FormControl>
        <Typography>
          {`Minimum tokens: ${sale.minimumTokenBuyPerUser} 
        / Maximum tokens: ${MAX_BUY_ALLOWANCE}`}
        </Typography>
        <Typography sx={{ color: '#e1534f' }}>
          {errors.quantity && 'Enter a correct amount'}
        </Typography>
      </Box>
    </>
  );
}

export default Quantity;
