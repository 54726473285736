import React from 'react';
import Script from 'next/script';
import { IS_CI, IS_DEVELOPMENT } from '@/common/config';

function GoSquared() {
  if (IS_DEVELOPMENT || IS_CI) return null;
  return (
    <Script
      id="GoSquared"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
      !(function (g, s, q, r, d) {
  r = g[r] =
    g[r] ||
    function () {
      (r.q = r.q || []).push(arguments);
    };
  d = s.createElement(q);
  q = s.getElementsByTagName(q)[0];
  d.src = '//d1l6p2sc9645hc.cloudfront.net/tracker.js';
  q.parentNode.insertBefore(d, q);
})(window, document, 'script', '_gs');

_gs('GSN-125016-T');
_gs('set', 'anonymizeIP', true);
_gs('set', 'trackLocal', true);
`
      }}
    />
  );
}

export default GoSquared;
