import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Box, CircularProgress } from '@mui/material';

interface DisplayNumberFormatProps {
  value: string | number;
  decimalScale?: number;
  prefix?: string;
  suffix?: string;
  placeholder?: string | number;
  max?: string | number | null;
  name?: string;
  onChange?: (v: string) => void;
  isLoading?: boolean;
}

export const getValueLimit =
  (MAX_VAL: string | number) =>
  ({ floatValue }: NumberFormatValues) =>
    !floatValue || floatValue <= Number(MAX_VAL);

export default function DisplayNumberFormat({
  value,
  decimalScale = 0,
  prefix = '',
  suffix = '',
  placeholder = '',
  max,
  name,
  onChange,
  isLoading = false
}: DisplayNumberFormatProps) {
  return (
    <>
      {isLoading ? (
        <Box sx={{ width: '100%', display: 'flex' }}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <NumberFormat
          name={name}
          isAllowed={max ? getValueLimit(max) : undefined}
          value={value}
          displayType={'text'}
          thousandSeparator={true}
          fixedDecimalScale={true}
          decimalScale={decimalScale}
          prefix={prefix}
          suffix={suffix}
          renderText={(value) => <>{value}</>}
          placeholder={String(placeholder)}
          onValueChange={(v) => {
            onChange?.(v.value);
          }}
        />
      )}
    </>
  );
}
