import React from 'react';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
import { createSaleInformation } from '@/services/api.service';
import { uploadToMinioWithPresigned } from '@/services/minio.service';
import { convertToSlug, fireErrorToast } from '@/utils';
import getCroppedImg, { ASPECT_RATIOS, useCropImage } from '@/utils/cropImage';
import { Box, Button, MenuItem, Slider, TextField } from '@mui/material';
import { Sale } from '@prisma/client';
import GenericModal from './GenericModal';

interface CropImageDialogProps {
  file: File;
  onClose: () => void;
  masterProjectId?: string;
  user?: string;
  sale?: Sale;
  onConfirm: (
    imageName: string,
    file?: File,
    reason?: 'upload' | 'cancel'
  ) => void;
  deferUpload?: boolean;
  name?: string;
}

function CropImageDialog({
  name,
  file,
  onClose,

  onConfirm,
  user,
  sale,
  deferUpload = false
}: CropImageDialogProps) {
  const {
    crop,
    aspect,
    setAspect,
    setCrop,
    setZoom,
    onCropComplete,
    tempSrc,
    zoom,
    reset,
    cropResult
  } = useCropImage({
    file
  });

  const handleClose = () => {
    onClose();
    tempSrc && URL.revokeObjectURL(tempSrc);
  };

  const handleConfirm = async () => {
    let loadingToast;
    const imageBlob = await getCroppedImg(
      tempSrc,
      cropResult?.croppedAreaPixels
    );

    const saleId = sale?.uuid;
    const saleName = convertToSlug(sale?.name);
    const fileExtension = file.name?.split('.').pop();
    // the milliseconds thing is to avoid cache of the image after upload.
    const timestamp = new Date().getMilliseconds();
    const imageName = `public/${user}/${saleName}/imageSale/${timestamp}/imageSale.${fileExtension}`;

    const newFile = new File([imageBlob], imageName);

    if (deferUpload && name) {
      onConfirm(name, newFile);
      if (tempSrc) {
        URL.revokeObjectURL(tempSrc);
      }
      return;
    }
    try {
      loadingToast = toast.loading('Uploading project picture...');
      await uploadToMinioWithPresigned({
        file: newFile,
        imageName
      });
      if (!saleId) {
        throw new Error('Missing saleId');
      }
      const { success } = await createSaleInformation(saleId, {
        imageSale: imageName
      });

      if (success) {
        toast.update(loadingToast, {
          type: 'success',
          // render: data,
          isLoading: false,
          autoClose: 2000,
          closeOnClick: true
        });

        onConfirm(imageName);
        handleClose();
        if (tempSrc) {
          URL.revokeObjectURL(tempSrc);
        }
      } else {
        // throw new Error(data.error);
      }
    } catch (e) {
      fireErrorToast(e, { async: loadingToast });
    }
  };

  return (
    <GenericModal
      onClose={handleClose}
      title={'Adjust your image'}
      open={!!file}
      innerModalSx={{ width: 'minmax(300px, 80vw)' }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: { xs: 300, sm: 500, xl: 900 },
            position: 'relative',
            aspectRatio: '16/9'
          }}
        >
          <Cropper
            image={tempSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2rem',
            px: '2rem',
            width: '100%'
          }}
        >
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(_e, zoom) => setZoom(Number(zoom))}
            classes={{ root: 'slider' }}
          />
          <TextField
            size={'small'}
            select
            id={'aspect-ratio-select'}
            variant={'outlined'}
            label={'Aspect Ratio'}
            type={'select'}
            value={aspect}
            sx={{ flexShrink: 0, minWidth: 100 }}
            InputLabelProps={{
              shrink: true
            }}
            onChange={(e) => setAspect(Number(e.target.value))}
            // disabled
          >
            {ASPECT_RATIOS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={{ gap: '1rem', display: 'flex' }}>
          <Button variant={'outlined'} sx={{ flex: 1 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant={'outlined'} sx={{ flex: 1 }} onClick={() => reset()}>
            Reset
          </Button>
          <Button
            variant={'contained'}
            sx={{ flex: 1 }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </GenericModal>
  );
}

export default CropImageDialog;
