import { Dispatch, SetStateAction } from 'react';
import { SessionUser } from '@/common/types/next-auth';
import MyModal from '@/components/Modals';
import BankTransfer from '@/components/Modals/BankTransfer';
import ConfirmTransaction from '@/components/Modals/ConfirmTransaction';
import Contract from '@/components/Modals/Contract';
import CryptoWarning from '@/components/Modals/CryptoWarning';
import LoadingModal from '@/components/Modals/LoadingModal';
import PendingTransaction from '@/components/Modals/PendingTransaction';
import WalletLogIn from '@/components/Modals/WalletLogin';
import { SaleTransactions } from '@prisma/client';
import { TransactionModalTypes } from '@/common/enums';
import { ActiveSale } from '@/common/types/sales';

interface TokenModalProps {
  openModal: TransactionModalTypes | null;
  handleModal: (arg: TransactionModalTypes | null) => void;
  transaction?: SaleTransactions | null;
  setLoading?: (loading: boolean) => void;
  user: SessionUser;
  setContract?: Dispatch<SetStateAction<string>>;
  sale: ActiveSale;
}
function TokenModal({
  openModal,
  handleModal,
  transaction,
  user,
  setContract,
  sale
}: TokenModalProps) {
  return (
    <MyModal
      open={!!openModal}
      onClose={() => {
        handleModal(null);
      }}
      title={'Wallet LogIn'}
      description={'authentication'}
    >
      {openModal === TransactionModalTypes.Loading && <LoadingModal />}
      {openModal === TransactionModalTypes.WalletLogin && (
        <WalletLogIn onClose={() => handleModal(null)} />
      )}
      {openModal === TransactionModalTypes.PendingTx && !!transaction && (
        <PendingTransaction
          onClose={() => handleModal(null)}
          transaction={transaction}
        />
      )}
      {openModal === TransactionModalTypes.ManualTransfer && (
        <BankTransfer
          onClose={() => handleModal(null)}
          value={transaction?.amountPaid}
          currency={transaction?.amountPaidCurrency}
          onConfirm={() => handleModal(TransactionModalTypes.ConfirmPayment)}
        />
      )}
      {openModal === TransactionModalTypes.ConfirmPayment && !!transaction && (
        <ConfirmTransaction
          transaction={transaction}
          onClose={() => handleModal(null)}
          user={user}
        />
      )}
      {openModal === TransactionModalTypes.CryptoWarning && (
        <CryptoWarning
          onClose={() => handleModal(null)}
          address={sale.toWalletsAddress}
        />
      )}
      {openModal === TransactionModalTypes.Contract && !!setContract && (
        <Contract handleModal={handleModal} setContract={setContract} />
      )}
    </MyModal>
  );
}

export default TokenModal;
