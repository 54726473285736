import { ReactNode } from 'react';
import { Box, Fade, SxProps } from '@mui/material';
import { MuiSpinner } from '../Spinner';

interface LoadingContainerProps {
  loading: boolean;
  children?: ReactNode;
  sx?: SxProps;
}

const LoadingContainer = ({
  loading,
  children,
  sx = {}
}: LoadingContainerProps) => {
  return loading ? (
    <MuiSpinner color={'primary'} />
  ) : (
    <Fade in={true} timeout={750}>
      <Box
        sx={{
          padding: { xs: '0rem 1rem', md: '1rem 2rem', lg: '1rem 4rem' },
          marginBottom: { xs: '5rem', sm: 0 },
          ...sx
        }}
      >
        {children}
      </Box>
    </Fade>
  );
};

export default LoadingContainer;
