import React, { useContext, useRef, useState } from 'react';
import { useSession } from 'next-auth/react';
import PinInput from 'react-pin-input';
import { toast } from 'react-toastify';
import { isSaleStatusOpen } from '@/containers/Sales/functions';
import { ThemeContext } from '@/pages/_app';
import { createEmailVerification, useME } from '@/services/api.service';
import { callAPI } from '@/services/fetch.service';
import { generateRandomToken } from '@/utils';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Chip,
  Box,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';
import { EmailVerificationStatus, SaleStatus } from '@prisma/client';
import { CustomTooltip } from '../Tooltip';

interface AccountStatusProps {
  onClick?: () => void;
  onChange?: () => void;
  status?: SaleStatus;
}

const AccountStatusContent: React.FC<AccountStatusProps> = ({
  onChange,
  status
}) => {
  const { data } = useSession();
  const inputRef = useRef<HTMLInputElement>();
  const { data: userDbData, mutate, isLoading } = useME();
  const userEmail = userDbData?.user?.profile?.email;
  const userId = userDbData?.user?.sub;
  const isEmailVerify = userDbData?.user?.isEmailVerify;
  const user = data?.user;
  // @ts-expect-error TODO! FIXME
  const [isDarkMode] = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [tokenValue, setTokenValue] = useState<string | null>();
  const [notToken, setNotToken] = useState(true);
  const isPending = isEmailVerify === EmailVerificationStatus.PENDING;

  const isSaleOpen = isSaleStatusOpen(status);

  const handleMail = async (e) => {
    e.preventDefault();
    const email = inputRef?.current?.value;
    if (!email) return toast.error('Please enter a valid email');

    setLoading(true);
    const token = generateRandomToken();

    try {
      const { success } = await createEmailVerification({
        email,
        token,
        userId
      });

      if (success) {
        toast.success('Verification email sent!');
        mutate();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  const handleVerification = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await callAPI({
        url: `/emailVerification?token=${tokenValue}`,
        method: 'GET'
      });

      if (data) {
        toast.success('Email verified');
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.error);
    } finally {
      setLoading(false);
      mutate();
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await callAPI({
        url: `/emailVerification`,
        method: 'delete'
      });

      toast.success('Please enter your email for a new verification token');
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.error);
    } finally {
      setLoading(false);
      mutate();
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        gap: '1rem',
        height: '100%'
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Chip
          color={isSaleOpen ? 'success' : 'warning'}
          label={isSaleOpen ? SaleStatus.OPEN : SaleStatus.CLOSED}
        />
      </Box>
      {isLoading ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={36} />
        </Box>
      ) : (
        <div>
          <Box
            component={'form'}
            onSubmit={tokenValue && isPending ? handleVerification : handleMail}
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'flex-start',
              gap: '1rem'
            }}
          >
            {user?.isSiwe === true && (
              <>
                {isPending ? (
                  <Box
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: '1rem'
                    }}
                  >
                    <div>
                      <PinInput
                        onChange={(value) => {
                          if (value.length < 6) {
                            setNotToken(true);
                            setTokenValue(null);
                          }
                        }}
                        onComplete={(value) => {
                          setNotToken(false);
                          setTokenValue(value);
                        }}
                        length={6}
                        secret
                        secretDelay={200}
                        inputStyle={{
                          borderColor: '#178097',
                          borderRadius: '10px',
                          width: '30px',
                          height: '30px',
                          color: `${isDarkMode ? 'white' : 'black'}`
                        }}
                        inputFocusStyle={{ borderColor: '#131788' }}
                        focus
                        type="numeric"
                      />
                      <Typography
                        fontSize={'0.75rem'}
                        sx={{
                          fontSize: '.75rem',
                          color: 'common.helperText',
                          fontFamily:
                            'Sora,Overpass,Roboto Flex,system-ui,sans-serif',
                          fontWeight: 400,

                          lineHeight: 1.66
                        }}
                      >
                        Token expires in 3 hours. If it does, re-enter your
                        email for a new one.
                      </Typography>
                    </div>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        justifyContent: { xs: 'center', md: 'flex-start' }
                      }}
                    >
                      <LoadingButton
                        color={'primary'}
                        variant={'contained'}
                        type={'submit'}
                        loading={loading && !notToken}
                        loadingIndicator={<CircularProgress size={18} />}
                        //TODO link mail with wallet
                        disabled={notToken}
                        sx={{ paddingInline: '0.5rem' }}
                      >
                        Verify
                      </LoadingButton>
                      <CustomTooltip
                        label={
                          "If you want to verify another email or haven't received the token, click here."
                        }
                      >
                        <LoadingButton
                          onClick={handleDelete}
                          color={'primary'}
                          variant={'contained'}
                          type={'submit'}
                          loading={loading}
                          loadingIndicator={<CircularProgress size={18} />}
                          sx={{
                            paddingInline: '.5rem',
                            minWidth: '23px'
                          }}
                        >
                          <CachedOutlinedIcon fontSize="small" />
                        </LoadingButton>
                      </CustomTooltip>
                    </Box>
                  </Box>
                ) : (
                  <>
                    {' '}
                    <TextField
                      sx={{ flex: 1 }}
                      variant={'standard'}
                      onChange={onChange}
                      placeholder={'your@email.com'}
                      type={'email'}
                      inputRef={inputRef}
                      value={userEmail}
                      disabled={userEmail ? true : false}
                      helperText={
                        userEmail
                          ? 'Your email has been linked to your account'
                          : 'Link your email to your wallet account (optional)'
                      }
                    />
                    {!userEmail && (
                      <LoadingButton
                        color={'primary'}
                        variant={'contained'}
                        type={'submit'}
                        loading={loading}
                        loadingIndicator={<CircularProgress size={18} />}
                        sx={{ paddingInline: '0.5rem' }}
                      >
                        Send
                      </LoadingButton>
                    )}
                  </>
                )}
              </>
            )}
            {user?.isSiwe === false && (
              <TextField
                label={'Email'}
                fullWidth
                variant={'standard'}
                onChange={onChange}
                type={'email'}
                value={user?.email}
                disabled
                InputLabelProps={{ shrink: true }}
                helperText={
                  user.email_verified
                    ? 'Email has been verified ✅'
                    : 'Please verify your email here'
                }
              />
            )}
          </Box>
        </div>
      )}
    </Box>
  );
};

export default AccountStatusContent;
