/* eslint-disable no-redeclare */
import { IStorage } from '@/common/types/interfaces';

class Storage implements IStorage {
  private _sessionStorageSupported: boolean;

  constructor() {
    if (typeof window === 'undefined') return;
    try {
      sessionStorage.setItem('testingSessionStorage', 'test');
      sessionStorage.removeItem('testingSessionStorage');
      this._sessionStorageSupported = false;
    } catch (e) {
      throw Error('Storage - the browser does not support session storage.');
    }
  }

  get<T>(key: string): unknown | T {
    if (typeof window === 'undefined') return;
    if (this._sessionStorageSupported) {
      const item = sessionStorage.getItem(key);

      try {
        if (item != null) {
          return JSON.parse(item) as T;
        } else {
          return null;
        }
      } catch (e) {
        const number = Number(sessionStorage.getItem(key));
        return isNaN(number) ? sessionStorage.getItem(key) : number;
      }
    } else {
      const item = localStorage.getItem(key);

      try {
        if (item != null) {
          return JSON.parse(item) as T;
        } else {
          return null;
        }
      } catch (e) {
        const number = Number(localStorage.getItem(key));
        return isNaN(number) ? localStorage.getItem(key) : number;
      }
    }
  }

  save(key: string, value: any): void {
    if (typeof window === 'undefined') return;
    if (this._sessionStorageSupported) {
      if (typeof value !== 'string') {
        sessionStorage.setItem(key, JSON.stringify(value));
      } else {
        sessionStorage.setItem(key, value);
      }
    } else {
      if (typeof value !== 'string') {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, value);
      }
    }
  }

  delete(key: string): void {
    if (typeof window === 'undefined') return;
    if (this._sessionStorageSupported) {
      sessionStorage.removeItem(key);
    } else {
      localStorage.removeItem(key);
    }
  }

  clear(): void {
    if (typeof window === 'undefined') return;
    if (this._sessionStorageSupported) {
      sessionStorage.clear();
    } else {
      localStorage.clear();
    }
  }

  saveInLocalStorage(key: string, value: any): void {
    if (typeof window === 'undefined') return;
    localStorage.setItem(key, value);
  }

  getFromLocalStorage(key: string) {
    if (typeof window === 'undefined') return;
    return localStorage.getItem(key);
  }
}

export default new Storage();
