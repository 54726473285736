import Head from 'next/head';

interface SEOProps {
  title?: string;
  description?: string;
  allowRobots?: boolean;
  children?: React.ReactNode;
}

const defaultDescription =
  'Smat is an investment platform that connects deal sponsors with wealth managers, allowing investors to subscribe financial products with low intermediary costs.';

const SEO: React.FC<SEOProps> = ({
  title,
  description = defaultDescription,
  allowRobots = false,
  children
}) => (
  <Head>
    <title>
      {' '}
      {title
        ? `${title} | SMAT The Real Asset Platform`
        : 'SMAT | The Real Asset Platform'}{' '}
    </title>
    <meta name="description" content={description} />
    {allowRobots ? (
      <meta name="robots" content="follow" />
    ) : (
      <meta name="robots" content="none" />
    )}
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
    />
    {children}
  </Head>
);

export default SEO;
