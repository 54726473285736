import { ReactNode } from 'react';
import { Box, Card } from '@mui/material';
import { SmatIsoLogo } from '../Logos/Logos';
import { Subtitle, Title } from '../Typography/CommonTitles';

// TODO ! remove amount default value

export const TokenTitleElement = ({ amount, symbol }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: ' flex-start',
        gap: '0.5rem'
      }}
    >
      <Title sx={{ color: '#5e636b', fontSize: '.75rem', fontWeight: 700 }}>
        BALANCE
      </Title>
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
          '& span:nth-child(1)': {
            fontWeight: 700,
            fontSize: '1rem'
          },
          '& span:nth-child(2)': {
            color: '#5e636b',
            fontWeight: 700,
            fontSize: '0.75rem'
          }
        }}
      >
        <span>{amount || 0}</span>
        <span>{symbol.toUpperCase()}</span>
      </Box>
    </Box>
  );
};

interface CardTitleProps {
  icon?: ReactNode;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  sx?: object;
  titleElement?: ReactNode;
}

export const CardTitle: React.FC<CardTitleProps> = ({
  title = '',
  subtitle = '',
  sx = {},
  titleElement
}) => {
  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      {titleElement && <TokenImg />}
      {titleElement || (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '0.5rem',
            ...sx
          }}
        >
          <>
            <Title sx={{ color: '#5e636b', fontSize: '1rem', fontWeight: 700 }}>
              {title}
            </Title>
            <Subtitle>{subtitle}</Subtitle>
          </>
        </Box>
      )}
    </Box>
  );
};

export const TokenImg = () => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '3.5rem',
        width: '3.5rem',
        borderRadius: '50%',
        border: '0.5px solid common.bg',
        boxShadow: 2
      }}
    >
      <SmatIsoLogo sx={{ bgcolor: 'transparent' }} />
    </Box>
  );
};

export const DashboardCard = ({ children, header, sx = {} }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '1.5rem',
        boxShadow: 3,
        borderRadius: '10px',
        minHeight: '11rem',
        height: '100%',
        bgcolor: 'common.bg',
        ...sx
      }}
    >
      {header && <div>{header}</div>}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '.5rem 1rem',
          gap: '1.5rem',
          flex: 1
        }}
      >
        {children}
      </Box>
    </Card>
  );
};
