import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Button from './ButtonNav';
import getNavigationButtons from './getNavigationButtons';

const PAST_TAB = 'past';
const PENDING_TAB = 'pending';

const ButtonsNav = () => {
  const { push, pathname, query } = useRouter();
  const { id, postId, projectStatusUrl } = query;
  const [navButtons, setNavButtons] = useState([]);
  const [checked, setChecked] = useState(pathname);

  const handleOnClick = (button) => {
    setChecked(button.url);
    push(button.url);
  };
  const setNavigation = () => {
    let buttons = '';
    if (pathname.includes('/portfolio/wm/')) buttons = 'PORTFOLIO_WM';
    if (pathname.includes('/portfolio/client/')) buttons = 'PORTFOLIO_CLIENT';
    if (pathname.includes('/allWalletsInvestor'))
      buttons = 'ALL_WALLETS_INVESTOR';
    if (pathname.includes('/secondaryMarket')) buttons = 'SECONDARY_MARKET';
    if (pathname.includes('/project/')) buttons = 'PROJECT_OVERVIEW';
    if (pathname.includes('/distribution')) buttons = 'WALLET_DISTRIBUTION';
    if (pathname.includes('/distribution/project/')) buttons = 'PROJECT_DETAIL';
    if (pathname === '/portfolio') buttons = 'PORTFOLIO_WM';
    if (['/marketCampaigns', '/marketCampaigns/completed'].includes(pathname))
      buttons = 'CAMPAIGNS';
    if (['/projects', '/projects/[projectStatusUrl]'].includes(pathname))
      buttons = 'MARKETPLACE';
    return setNavButtons(getNavigationButtons(buttons, id, postId));
  };

  useEffect(() => {
    setNavigation();
  }, [pathname]);

  const checkIfActiveTab = (pathname, button) => {
    if ([button.url, button.url2, button.url3].includes(pathname)) return true;
    if (button.url.includes(PAST_TAB) && projectStatusUrl === PAST_TAB)
      return true;
    if (button.url.includes(PENDING_TAB) && projectStatusUrl === PENDING_TAB)
      return true;
  };

  const NavButton = ({ button }) => (
    <Button
      isActive={checkIfActiveTab(checked, button)}
      onClick={() => handleOnClick(button)}
    >
      {button.name}
    </Button>
  );

  return navButtons?.length > 0 ? (
    <div className="buttonsNav__container">
      {navButtons?.map((button, index) => (
        <NavButton button={button} key={index} />
      ))}
    </div>
  ) : null;
};

export default ButtonsNav;
