import { useState, useEffect, ReactElement } from 'react';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import WhitepaperIcon from '@/components/icons/Whitepaper.icon';
import { OpenInNew } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Button, { ButtonProps } from './Button';

interface SidebarButtonLinks {
  name: string;
  url: string;
  url2?: string;
  message?: string;
  icon: ReactElement;
  link?: boolean;
}

const PITCH_DECK_URL = {
  en: process.env.NEXT_PUBLIC_PITCHDECK_EN,
  fr: process.env.NEXT_PUBLIC_PITCHDECK_FR
};

const getSectionLinks = (locale): SidebarButtonLinks[] => [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: <HomeIcon />
  },
  {
    name: 'Buy Token',
    url: '/buy-token/overview',
    icon: <MonetizationOnIcon />
  },
  {
    name: 'Transactions',
    url: '/transactions',
    icon: <AccountBalanceWalletIcon />
  },
  {
    name: 'FAQ',
    url: '/faq',
    icon: <InfoIcon />
  },
  {
    name: 'Whitepaper',
    url: 'https://docs.smat.io',
    icon: <WhitepaperIcon />,
    link: true
  },
  {
    name: 'Pitch deck',
    url: PITCH_DECK_URL[locale] || process.env.NEXT_PUBLIC_PITCHDECK_EN,
    icon: <MenuBookIcon />,
    link: true
  },
  {
    name: 'Admin',
    url: '/admin',
    icon: <AdminPanelSettingsIcon />
  }
];

const ButtonsSidebar = () => {
  const { pathname, push } = useRouter();
  const [checked, setChecked] = useState(pathname);
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { data } = useSession();

  const isAdmin = data?.user?.isAdmin;

  const handleOnClick = (button) => {
    setChecked(button.url);
    push(button.url);
  };

  useEffect(() => {
    if (pathname.includes('/dashboard')) {
      setChecked('/dashboard');
    }
    if (pathname.includes('/buy-token/overview')) {
      setChecked('/buy-token/overview');
    }
    if (pathname.includes('/transactions')) {
      setChecked('/transactions');
    }
    if (pathname.includes('/faq')) {
      setChecked('/faq');
    }
    if (pathname.includes('/whitepaper')) {
      setChecked('/whitepaper');
    }
    if (pathname.includes('/pitchdeck')) {
      setChecked('/pitchdeck');
    }
    if (pathname.includes('/admin')) {
      setChecked('/admin');
    }
  }, []);

  interface ButtonUIProps extends ButtonProps {
    button: SidebarButtonLinks;
    link?: boolean;
  }

  const ButtonUI = ({
    button,
    style,
    divider = false,
    onClick = () => handleOnClick(button),
    link = false
  }: ButtonUIProps) => {
    return (
      <Button
        divider={divider}
        key={button.name}
        isActive={checked === button.url || checked === button.url2}
        onClick={onClick}
        icon={button.icon}
        style={style}
      >
        {button.name}
        {link && <OpenInNew sx={{ fontSize: '12px', marginLeft: '5px' }} />}
      </Button>
    );
  };

  const ADMIN_BUTTON = 'Admin';

  const Links = () => (
    <>
      {getSectionLinks(locale).map((button) =>
        button.link ? (
          <a
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={button.name}
            href={button.url}
            rel={'nofollow nooponer noreferrer'}
            target={'_blank'}
          >
            <ButtonUI onClick={() => undefined} button={button} link />
          </a>
        ) : !isAdmin && button.name === ADMIN_BUTTON ? null : (
          <ButtonUI key={button.name} button={button} />
        )
      )}
    </>
  );

  return (
    <div className="buttonsSidebar">
      <Links />
    </div>
  );
};

export default ButtonsSidebar;
