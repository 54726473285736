import React, { useEffect, useState } from 'react';
import MyModal from '@/components/Modals';
import WalletLogIn from '@/components/Modals/WalletLogin';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Chip } from '@mui/material';

const WalletNetwork = ({ isConnected }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModal = (state: boolean) => setOpenModal(state);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value
  //   );
  // };

  useEffect(() => {
    if (isConnected) {
      handleModal(false);
    }
  }, [isConnected]);

  return (
    <div>
      <MyModal
        open={openModal}
        onClose={() => handleModal(false)}
        title={'Wallet LogIn'}
        description={'authentication'}
      >
        <WalletLogIn onClose={() => handleModal(false)} />
      </MyModal>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between!important'
        }}
      >
        <Chip
          sx={isConnected ? {} : { boxShadow: 2 }}
          clickable={!isConnected}
          onClick={isConnected ? undefined : () => handleModal(true)}
          label={isConnected ? 'Connected' : 'Connect'}
          variant="outlined"
          icon={
            isConnected ? (
              <RadioButtonCheckedIcon
                fontSize="small"
                sx={{
                  color: '#2ed47a !important'
                }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                fontSize="small"
                sx={{
                  color: 'default !important'
                }}
              />
            )
          }
        />
        {/* {isConnected ? (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={handleChange}
              defaultValue={chain?.name}
            >
              {chains.map((x) => (
                <MenuItem
                  key={x.id}
                  value={x.name}
                  onClick={() => switchNetwork?.(x.id)}
                >
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null} */}
      </Box>
    </div>
  );
};

export default WalletNetwork;
