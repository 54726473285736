import React, { useEffect, useState } from 'react';
import { useSale, useUserTransactions } from '@/services/api.service';
import { MINIO_PUBLIC_URL } from '@/services/minio.service';
import { stringAvatar } from '@/utils';
import { Avatar, Box, CircularProgress } from '@mui/material';
import { Sale, TransactionStatus } from '@prisma/client';
import { TokenImg, TokenTitleElement } from './DashboardCard';

interface allTransaction {
  amount: number;
  delivered: number;
}

interface tokenContent {
  userId?: string;
  sale?: Sale;
}
function TokenBalanceContent({ userId, sale }: tokenContent) {
  const [allTransaction, setAllTransaction] = useState<allTransaction | null>();
  const { data: information } = useSale(sale?.uuid);
  const tokenSrc = MINIO_PUBLIC_URL + information?.saleInformation?.imageToken;
  const symbol = sale ? sale?.tokenSymbol : 'SMAT';
  const tokenName = information?.sale?.tokenName;

  const { data, isLoading } = useUserTransactions({ userId, symbol });

  useEffect(() => {
    if (data?.transactions && !isLoading) {
      const val = data?.transactions?.reduce(
        (agg, el) => {
          agg.amount += el.boughtTokenQuantity;
          if (el.status === TransactionStatus.DELIVERED) {
            agg.delivered += el.boughtTokenQuantity;
          }
          return agg;
        },
        { amount: 0, delivered: 0 }
      );

      setAllTransaction(val);
    }
  }, [data?.transactions]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: ' 0.5rem',
        paddingBottom: '0.5rem',
        '& p': {
          textTransform: 'uppercase',

          color: '#5e636b',
          fontSize: '0.75rem',
          fontWeight: 700
        },
        '& span': {
          fontWeight: 'bold'
        }
      }}
    >
      {isLoading ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={36} />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            {sale ? (
              <Avatar
                {...stringAvatar(tokenName)}
                src={tokenSrc}
                alt={tokenName}
                sx={{ height: '3.5rem', width: '3.5rem' }}
              />
            ) : (
              <TokenImg />
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <TokenTitleElement
                amount={allTransaction?.amount}
                symbol={`${sale ? sale.tokenSymbol : 'SMAT'}`}
              />
              <TokenBodyElement
                delivered={allTransaction?.delivered}
                symbol={`${sale ? sale.tokenSymbol : 'SMAT'}`}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

const TokenBodyElement = ({ delivered, symbol }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: ' flex-start',
        gap: '0.5rem'
      }}
    >
      <p>Delivered Tokens</p>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
        <span>{delivered || 0}</span>
        <p>{symbol}</p>
      </Box>
      {/* <div>
          <span>{amountPaid}</span>
          <p>CHF</p>
        </div> */}
    </Box>
  );
};

export default TokenBalanceContent;
