export enum HttpStatus {
  OK = 200,

  CREATED = 201,
  CONFLICT = 409,
  NOT_ACCEPTABLE = 406,
  INTERNAL_SERVER_ERROR = 500,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  FOUND = 302
}
