import { Typography, TypographyProps } from '@mui/material';

export const Title = ({
  children,
  sx = {},
  align = 'center',
  ...props
}: TypographyProps<'h5'>) => {
  return (
    <Typography
      component={'h4'}
      sx={{
        fontSize: { xs: '1.15rem', md: '1.25rem', lg: '1.5rem' },
        textAlign: { xs: 'center', md: 'left' },
        fontFamily: 'Sora',
        ...sx
      }}
      align={align}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Subtitle = ({
  children,
  sx = {},
  align = 'center',
  ...props
}: TypographyProps) => {
  return (
    <Typography
      variant={'body2'}
      align={align}
      sx={{
        fontSize: { xs: '.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' },
        textAlign: { xs: 'justify', md: 'left' },
        ...sx
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Paragraph = ({ children, sx = {}, ...props }: TypographyProps) => {
  return (
    <Typography
      variant={'body1'}
      sx={{
        fontSize: 'clamp(0.75rem, 0.5vw + 1rem, 1rem)',
        ...sx
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
