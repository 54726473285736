import React from 'react';
import { useConnect } from 'wagmi';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import MetaMask from '../icons/MetaMask.icon';
import WalletConnect from '../icons/WalletConnect.icon';
import { Paragraph } from '../Typography/CommonTitles';

const IS_METAMASK = 'MetaMask';

const WalletLogIn = ({ onClose }) => {
  const { connect, connectors } = useConnect();

  return (
    <Card sx={{ borderRadius: '10px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '0.5rem 0rem'
        }}
      >
        <Typography
          color={'primary'}
          sx={{ fontWeight: 'bold' }}
          variant="subtitle1"
        >
          Connect wallet
        </Typography>
        <IconButton color={'primary'} sx={{ boxShadow: 0 }} onClick={onClose}>
          <CloseIcon width={24} />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ p: '1rem 2rem 2rem 2rem' }}>
        <Paragraph>Connect with your preferred wallet</Paragraph>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.5rem',
            mt: '1rem'
          }}
        >
          <>
            {connectors.map((connector) => (
              <Button
                key={connector.name}
                fullWidth
                color={'primary'}
                variant={'outlined'}
                name={connector.name}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                onClick={() => {
                  connect({ connector });
                }}
                endIcon={
                  connector.name === IS_METAMASK ? (
                    <MetaMask />
                  ) : (
                    <WalletConnect />
                  )
                }
              >
                {connector.name}
              </Button>
            ))}
          </>
        </Box>
      </Box>
    </Card>
  );
};

export default WalletLogIn;
