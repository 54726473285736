import React, { Dispatch, SetStateAction, useState } from 'react';
import { DateTime } from 'luxon';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { SessionUser } from '@/common/types/next-auth';
import { confirmTransaction, useSale } from '@/services/api.service';
import { uploadToMinioWithPresigned } from '@/services/minio.service';
import formatNumber, { IntlNumberFormat } from '@/utils/FormatNumber';
import { dateFormatterInput } from '@/utils/dates';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import { SaleTransactions, TransactionStatus } from '@prisma/client';
import DropzoneTextfield from '../Input/DropzoneTextfield';
import { styles } from './styles';
import currencyJs from 'currency.js';
import { onInvalid } from '@/utils';

interface ConfirmTransactionProps {
  transaction: SaleTransactions;
  onClose: Dispatch<SetStateAction<unknown>>;
  user: SessionUser;
}

interface FormValues {
  confirmationId: string;
  comment?: string;
}

function ConfirmTransaction({
  transaction,
  onClose,
  user
}: ConfirmTransactionProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useSale(transaction?.saleId);
  const activeSale = data?.sale;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    setValue
  } = useForm<FormValues>();
  const { push } = useRouter();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    setIsLoading(true);
    try {
      if (!transaction?.uuid) {
        throw new Error('Transaction UUID is missing');
      }

      const updatedTransaction = {
        ...formData,
        statusTx: TransactionStatus.CONFIRMED_BY_USER,
        uuid: transaction.uuid
      };

      const { success } = await confirmTransaction(updatedTransaction);

      if (success) {
        toast.success('Transaction updated!');
        push({
          pathname: '/status/success',
          query: {
            saleId: data?.sale?.uuid
          }
        });
        onClose(null);
      } else {
        throw new Error('Transaction update failed');
      }
    } catch (error) {
      console.error('Error updating transaction:', error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFilesChange = async (
    name: 'confirmationId',
    file: File | null,
    reason: 'upload' | 'clear'
  ) => {
    setIsLoading(true);
    if (reason === 'clear') {
      return resetField(name);
    }
    if (file) {
      if (!activeSale || !user || !transaction) {
        throw new Error('Missing required data: sale, user or transaction');
      }
      const date = DateTime.now().toFormat('yyyy-MM-dd');
      const fileExtension = file.name?.split('.').pop();
      const relativePath = `sales/${activeSale?.uuid}/${date}/${user?.sub}/${transaction?.uuid}/receipt.${fileExtension}`;

      const loaderToast = toast.loading('Uploading document...');
      setValue(name, relativePath);
      try {
        await uploadToMinioWithPresigned({ imageName: relativePath, file });
        toast.update(loaderToast, {
          type: 'success',
          render: 'Uploaded successfully',
          isLoading: false,
          autoClose: 2000,
          closeOnClick: true
        });
      } catch (e) {
        toast.update(loaderToast, {
          type: 'error',
          render: `${e.message}`,
          isLoading: false,
          autoClose: 2000,
          closeOnClick: true
        });
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <Fade in={true} timeout={1000}>
      <Card>
        <Box sx={styles.login}>
          <Typography variant="subtitle1">Confirm transaction</Typography>
          <IconButton color={'primary'} sx={{ boxShadow: 0 }} onClick={onClose}>
            <CloseIcon width={24} />
          </IconButton>
        </Box>
        <Divider />
        <Stack spacing={2} sx={{ p: '1rem' }}>
          <Typography
            align={'justify'}
            variant={'body1'}
            sx={{ lineHeight: 1.6 }}
          >
            You are about to confirm payment of the following transaction,
            please provide us with the transaction id or reference provided by
            your bank for reconcilliation:
          </Typography>
          {transaction && (
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Typography sx={{ flexGrow: 0 }}>
                {dateFormatterInput({
                  date: transaction?.createdAt,
                  format: DateTime.DATE_SHORT
                })}
              </Typography>
              <Typography
                align={'center'}
                color={'primary'}
                sx={{ flex: '1 0' }}
              >
                -
              </Typography>
              <Typography sx={{ flex: 1, whiteSpace: 'nowrap' }}>
                {`${
                  transaction?.tokenSymbol
                } ${formatNumber(transaction?.boughtTokenQuantity)}`}
              </Typography>
              <Typography align={'center'} color={'primary'} sx={{ flex: 1 }}>
                for
              </Typography>
              <Typography sx={{ flex: 1, whiteSpace: 'nowrap' }}>
                {IntlNumberFormat(
                  currencyJs(transaction?.amountPaid, { precision: 2 }).value,
                  transaction?.amountPaidCurrency,
                  2
                )}
              </Typography>
            </Box>
          )}
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            component={'form'}
          >
            <TextField
              size="small"
              fullWidth
              label={'Remarks (optional)'}
              {...register('comment', { required: false })}
            />
            <DropzoneTextfield
              control={control}
              label={'Payment slip/receipt'}
              name={'confirmationId'}
              // required
              accept={['application/pdf', 'image/*']}
              variant={'filled'}
              onChange={handleFilesChange}
              helperText={
                errors['confirmationId'] ? 'Mandatory field' : undefined
              }
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Button
              sx={{ flex: 1 }}
              variant={'outlined'}
              fullWidth
              onClick={onClose}
            >
              Close
            </Button>
            <LoadingButton
              variant={'contained'}
              fullWidth
              loading={isLoading}
              loadingIndicator={<CircularProgress size={18} />}
              onClick={handleSubmit(onSubmit, onInvalid)}
            >
              Confirm payment
            </LoadingButton>
          </Box>
        </Stack>
      </Card>
    </Fade>
  );
}

export default ConfirmTransaction;
