import React, { ReactNode, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Accordion as AcordionMui,
  SxProps
} from '@mui/material';
import { Subtitle, Title } from '../Typography/CommonTitles';

interface AccordionProps {
  title: string;
  isTitle?: boolean;
  actions?: ReactNode;
  children: ReactNode | string;
  htmlContent?: boolean;
  smallTitle?: boolean;
  sx?: SxProps;
  sxTitle?: SxProps;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  isTitle = false,
  actions,
  children,
  htmlContent,
  sxTitle,
  sx
}) => {
  const [show, setShow] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setShow((show) => !show);
  };

  const DefaultActions = () => (
    <>
      <IconButton>
        {show ? (
          <KeyboardArrowDownIcon color="primary" />
        ) : (
          <KeyboardArrowRightIcon color="primary" />
        )}
      </IconButton>
    </>
  );

  return (
    <AcordionMui>
      <AccordionSummary
        sx={{
          borderRadius: '10px'
        }}
        onClick={(e) => handleClick(e)}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {isTitle ? (
            <Title sx={{ mb: '0.3rem', color: '#1c7391', ...sxTitle }}>
              {title}
            </Title>
          ) : (
            <Subtitle>{title}</Subtitle>
          )}

          <div>{actions || <DefaultActions />} </div>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {htmlContent ? (
          <div
            dangerouslySetInnerHTML={{ __html: String(children) }}
            className={`accordion__content`}
          />
        ) : (
          <Box sx={{ ...sx }}>{children}</Box>
        )}
      </AccordionDetails>
    </AcordionMui>
  );
};

export default Accordion;
