import React from 'react';
import { useTranslation, Trans } from 'next-i18next';
import Link from 'next/link';
import { Box, Button, Paper, Typography } from '@mui/material';

const TokenSaleContent = ({ sale }) => {
  const { t } = useTranslation();
  const valuePerCurrency = 1 / sale?.tokenPricePerUnit;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'space-around',
        height: '100%'
      }}
    >
      {!sale ? (
        <Paper
          sx={{
            p: 2,
            bgcolor: 'primary.main',
            borderRadius: '12px'
          }}
        >
          <Typography variant="h6" sx={{ color: 'white', textAlign: 'center' }}>
            <Trans t={t}>{'dashboard:tokenSale.commingSoon'}</Trans>
          </Typography>
        </Paper>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography sx={{ fontSize: '1.2rem' }}>
              1 {sale?.tokenName} = {sale?.tokenPricePerUnit}{' '}
              {sale?.saleCurrency}
            </Typography>
            <Typography sx={{ fontSize: '0.9rem', color: 'common.tableGray' }}>
              1 {sale?.saleCurrency} = {Number(valuePerCurrency).toFixed(4)}{' '}
              {sale?.tokenSymbol}
            </Typography>
          </Box>
          <Link href={'/buy-token/overview'} passHref legacyBehavior>
            <Button fullWidth color={'primary'} variant={'contained'}>
              {t('dashboard:tokenSale.buyToken')}
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
};

export default TokenSaleContent;
