import { IconButton, Typography } from '@mui/material';
import { ComponentProps, ReactNode, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';

interface PopoverButtonProps
  extends Omit<ComponentProps<typeof IconButton>, 'content'> {
  icon?: ReactNode;
  content: ReactNode;
  onClose?: () => void;
}

function PopoverButton({ sx, icon, onClose, id, content }: PopoverButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    onClose?.();
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton sx={{ boxShadow: 0, ...sx }} onClick={handleClick}>
        {icon || <InfoOutlinedIcon sx={{ color: '#1c7391' }} />}
      </IconButton>
      <Popover
        id={`popover-${id}`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Paper>
          {!content ? null : typeof content === 'string' ? (
            <Typography
              sx={{ p: 2, color: 'white', backgroundColor: '#178097' }}
            >
              {content}
            </Typography>
          ) : (
            content
          )}
        </Paper>
      </Popover>
    </>
  );
}

export default PopoverButton;
