import CookieConsent from 'react-cookie-consent';

const Cookie = () => {
  if (process.env.NODE_ENV !== 'production') return null;
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="SMAT_cookie_accept"
      cookieValue={'true'}
      enableDeclineButton
      disableStyles
      declineButtonText="Decline"
      declineButtonStyle={{
        backgroundColor: '#ffffff',
        color: 'rgb(0, 189, 213)',
        border: 'none',
        padding: '8px 20px'
      }}
      buttonStyle={{
        backgroundColor: 'rgb(0, 189, 213)',
        color: '#ffffff',
        border: 'none',
        borderRadius: '30px',
        padding: '8px 20px'
      }}
      contentStyle={{
        color: 'black',
        display: 'flex',
        margin: '2rem 1rem',
        flex: '1 0',
        fontSize: '11px'
      }}
      buttonWrapperClasses="cookieModal__btnWrapper"
      containerClasses="cookieModal"
      expires={150}
    >
      <p>
        Smat uses cookies to ensure the best possible user experience on our
        platform. In order to remember things like your language preferences and
        location, we need to store some information and to do this we use
        cookies. These files are safe and secure and will never store any
        sensitive personal or account information.{' '}
        <a
          className="cookieModal__link"
          href="https://www.smat.io/privacy-policy"
        >
          Learn more
        </a>
      </p>
    </CookieConsent>
  );
};

export default Cookie;
